@import "mixins";

@font-face {
  font-family: 'NanumSquareOTFLight';
  src: url('../../public/font/NanumSquareOTF_acL00.eot');
  src: url('../../public/font/NanumSquareOTF_acL00.eot?#iefix') format('embedded-opentype'),
  url('../../public/font/NanumSquareOTF_acL00.woff2') format('woff2'),
  url('../../public/font/NanumSquareOTF_acL00.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  //ascent-override: 65%;
  //descent-override: 0%;
}

@font-face {
  font-family: 'NanumSquareOTFRegular';
  src: url('../../public/font/NanumSquareOTF_acR00.eot');
  src: url('../../public/font/NanumSquareOTF_acR00.eot?#iefix') format('embedded-opentype'),
  url('../../public/font/NanumSquareOTF_acR00.woff2') format('woff2'),
  url('../../public/font/NanumSquareOTF_acR00.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  //ascent-override: 65%;
  //descent-override: 0%;
}

@font-face {
  font-family: 'NanumSquareOTFBold';
  src: url('../../public/font/NanumSquareOTF_acB00.eot');
  src: url('../../public/font/NanumSquareOTF_acB00.eot?#iefix') format('embedded-opentype'),
  url('../../public/font/NanumSquareOTF_acB00.woff2') format('woff2'),
  url('../../public/font/NanumSquareOTF_acB00.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  //ascent-override: 65%;
  //descent-override: 0%;
}

@font-face {
  font-family: 'NanumSquareOTFExtraBold';
  src: url('../../public/font/NanumSquareOTF_acEB00.eot');
  src: url('../../public/font/NanumSquareOTF_acEB00.eot?#iefix') format('embedded-opentype'),
  url('../../public/font/NanumSquareOTF_acEB00.woff2') format('woff2'),
  url('../../public/font/NanumSquareOTF_acEB00.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
  //ascent-override: 65%;
  //descent-override: 0%;
}

html {
  height: 100%;
  width: 100%;
  touch-action: pan-x pan-y;
}

body {
  height: 100%;
  width: 100%;
  margin: 0;
}

body * {
  //font-family: "Noto Sans KR", serif;
  font-family: NanumSquareOTFRegular;
  color: #1c2226;
}

a {
  text-decoration: none;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:focus {
  outline: none !important;
  background-color: transparent;
}
