div.surveyResultContainer {
  width: 100%;
  height: 100%;
  max-width: 1980px;
  //max-height: 1080px;
  background-color: white;
  overflow-x: hidden;
  //box-shadow: 0 20px 20px -4px rgba(0, 0, 0, 0.2);
  position: relative;
  padding-top: 30px;

  h2{
    width: 100%;
    margin: 50px 0 0 80px;
  }

  section.viewResultSection{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-left: 80px;
    div.viewResultBox{
      border-radius: 10px;
      background-color: #fafafa;
      //box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
      margin: 10px 20px 20px 0;
      padding: 10px;
      span{
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        h2{
          margin: 0;
          display: flex;
          width: auto;
        }
      }

    }
  }

  div.techBoardContainer {
    width: 100%;
    height: 100%;
    padding: 30px 80px 90px 80px;
    position: relative;
    //overflow-y: auto;
    //overflow-x: hidden;

    div.searchBox{
      width: 100%;
      height: 40px;
      display: flex;
      justify-content: space-between;
      div.inputCon{
        all: unset;
        width: 90%;
        height: 100%;
        border-radius: 5px;
        border: 1px solid #c4c4c4;
        position: relative;
        input{
          all: unset;
          width: 100%;
          height: 100%;
          padding-left: 50px;
        }
        span{
          position: absolute;
          top: 10px;
          left: 15px;
          color: #7c7c7c;
        }
      }
      button{
        width: 100px;
        height: 100%;
        color: #fff;
        background-color: #ff8800;
        border-radius: 5px;
        transition: 0.2s;
        opacity: 0.8;
        &:hover{
          opacity: 1
        }
      }
    }

    div.categoryBox{
      width: 100%;
      margin-top: 25px;
      padding-top: 50px;
      border-top: 1px solid #c4c4c4;
      display: flex;
      justify-content: space-between;
      align-items: center;

      ul.leftCon{
        width: 400px;
        display: flex;
        justify-content: space-between;
        padding: 0 20px;
        li{
          width: 25%;
        }
      }

      div.rightCon{
        display: flex;
        align-items: center;
        div{
          width: 100px;
        }
        button{
          width: 100px;
          height: 40px;
          border: 1px solid #c4c4c4;
          transition: 0.2s;
          &:hover{
            background-color: #c4c4c4;
            color: #fff;
          }
        }
      }

    }
    div.contentBox{
      width: 100%;
      margin-top: 5px;
      .content{
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 30px 20px;
        margin: 30px 0;
        border-top: 1px solid #c4c4c4;
        border-bottom: 1px solid #c4c4c4;

        &:first-child{
          margin-top: 10px;
        }

        div.txtCon{
          width: 80%;
          padding-right: 30px;
          line-height: 22px;
          h3{
            width: 80%;
          }
          div.txt{
            margin: 15px 0;
          }

          div.subCon{
            width: 100%;
            opacity: 0.7;
            span{
              margin-right: 40px;
              position: relative;
              &::after{
                content: '|';
                position: relative;
                right: -20px;
                opacity: 0.3;
              }
              &:first-child{
                font-weight: bold;
              }
            }
          }
        }
        div.answerCon{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding-right: 50px;
          div.circle{
            width: 80px;
            height: 80px;
            border-radius: 50%;
            border: 1px solid #c4c4c4;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            span{
              &:first-child{
                font-size: 20px;
                font-weight: bold;
              }
              &:last-child{
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1450px) {
  div.surveyResultContainer {
    h2{
    }

    section.viewResultSection{
      div.viewResultBox{
        span{
          h2{
          }
        }
      }
    }

    div.techBoardContainer {
      div.searchBox{
        div.inputCon{
          input{
          }
          span{
          }
        }
        button{
          &:hover{
          }
        }
      }

      div.categoryBox{
        ul.leftCon{
          li{
          }
        }

        div.rightCon{
          div{
          }
          button{
            &:hover{
            }
          }
        }

      }
      div.contentBox{

        .content{
          flex-direction: column;
          padding: 20px 0;
          margin: 0;
          &:first-child{
          }

          div.txtCon{
            display: flex;
            flex-direction: column;
            padding: 0;
            width: 100%;
            h3{
            }
            div.txt{
              display: flex;
              flex-direction: column;
              span.verticalBar{
                display: none;
              }
            }

            div.subCon{
              span{
                &::after{
                }
                &:first-child{
                }
              }
            }
          }
          div.answerCon{
            padding: 0;
            div.circle{
              span{
                &:first-child{
                }
                &:last-child{
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 916px) {
  div.surveyResultContainer {
    //overflow-y: visible;
    //max-height: 100%;
    h2{
      margin-left: 30px;
    }

    section.viewResultSection{
      padding-left: 30px;
      div.viewResultBox{
        span{
          h2{
          }
        }
      }
    }

    div.techBoardContainer {
      padding: 0 30px 0 30px;
      //overflow-y: visible;
      //overflow-x: visible;
      height: 100%;
      div.searchBox{
        div.inputCon{
          input{
          }
          span{
          }
        }
        button{
          &:hover{
          }
        }
      }

      div.categoryBox{
        ul.leftCon{
          li{
          }
        }

        div.rightCon{
          div{
          }
          button{
            &:hover{
            }
          }
        }

      }
      div.contentBox{

        .content{
          flex-direction: column;
          padding: 20px 0;
          margin: 0;
          &:first-child{
          }

          div.txtCon{
            display: flex;
            flex-direction: column;
            padding: 0;
            width: 100%;

            h3{
            }
            div.txt{
              display: flex;
              flex-direction: column;
              span.verticalBar{
                display: none;
              }
            }

            div.subCon{
              span{
                &::after{
                }
                &:first-child{
                }
              }
            }
          }
          div.answerCon{
            padding: 0;
            div.circle{
              span{
                &:first-child{
                }
                &:last-child{
                }
              }
            }
          }
        }
      }
    }
  }
}
