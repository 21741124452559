div.sideNaviContainer {
  border-right: 2px solid rgba(188, 188, 188, 0.5);
  div.sideNaviTop{
    img {
      margin-top: 20px;
      margin-left: 20px;
      cursor: pointer;
    }
    .menuIcon{
      display: none;
    }
  }

  div.sideNaviBox{
    div.sideNaviBoxTop {
      width: 300px;
      height: 100%;
      max-height: 700px;
      display: flex;
      font-size: 20px;
      line-height: 80px;
      text-indent: 2em;
      color: #292929;

      ul {
        list-style: none;
        padding: 0;
        li {
          width: 100%;
          text-decoration: none;
          font-weight: bold;
          color: #292929;
          cursor: pointer;

          &.active {
            background-color: pink;
          }

          &:hover {
            color: #609EAE;
          }
        }
      }

      *,
      *:before,
      *:after {
        box-sizing: border-box;
      }
    }

    div.loginBtnBox {
      width: 100%;
      height: 107px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &:hover {
        color: #609EAE;
      }
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        width: 200px;
        font-size: 20px;
        font-weight: bold;
        height: 50px;
        border: none;
        border-radius: 20px;
        cursor: pointer;

        &:hover {
          color: #609EAE;
        }
      }
    }
  }
}

@media (max-width: 916px) {
  div.sideNaviContainer {
    border: none;
    box-sizing: content-box;
    //position: fixed;
    //z-index: 9;
    //width: 100%;
    //background-color: white;
    //overflow-y: visible;
    div.sideNaviTop{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 10px;

      img {
        margin: 0;
      }
      .menuIcon{
        width: 40px;
        height: 40px;
        cursor: pointer;
        display: block;
        //background-color: #00EE8F;
      }
      &{
        box-shadow: 0px 2px 3px rgba(0,0,0,0.2);
      }
    }

    div.sideNaviBox{
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
      //border: 1px solid red;
      box-shadow: 0 2px 4px rgba(0,0,0,0.2);
      z-index: 9;
      margin-bottom: 3px;
      display: none;
      div.sideNaviBoxTop {
        line-height: 50px;
        height: auto;

        ul {

          li {
            &.active {
            }
          }
        }

        a {
        }

        *,
        *:before,
        *:after {
        }
      }

      div.loginBtnBox {
        height: 60px;

        button {
          &:hover {
          }
        }
      }
    }
  }
}
