@import "../color.scss";

div.loginContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: $background;

  div.loginBox {
    max-width: 656px;
    width: 100%;
    height: 440px;
    background-color: $white;
    font-size: 30px;
    border-radius: 8px;
    display: flex;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2);

    div.left {
      width: 256px;
      height: 100%;
      background-color: #48A0B0;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      div.loginBg{
        width: 100%;
        height: 100%;
        position: absolute;
        //background: url("../../images/loginBg.svg") center/cover no-repeat;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }
      div.logoImg{
        position: absolute;
        top: 84px;
        display: flex;
        flex-direction: row;
        background-color: white;
        width: 100%;
        justify-content: center;
        align-items: center;
        height: 50px;
        img{
          width: 100%;
          max-width: 30px;
          max-height: 30px;
          height: 100%;
          margin-right: 10px;
        }
        h3{
          color: #00EE8F;
        }
      }
    }

    div.right {
      width: 400px;
      height: 100%;
      padding: 24px;
      box-sizing: border-box;

      div.top {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: start;
        color: $dark;
        font-weight: 400;

        span.title {
          font-size: 32px;
        }

        div.inputLoginBox {
          width: 100%;
          margin-top: 32px;
          display: flex;
          flex-direction: column;

          div.content {
            width: 100%;
            display: flex;
            flex-direction: column;
            margin-bottom: 24px;
            &:last-child{
              margin-bottom: 20px;
            }

            label {
              margin-bottom: 8px;
              padding-left: 8px;
              font-size: 14px;
            }

            input {
              height: 40px;
              border: 1px solid #d4d4d4;
              border-radius: 8px;
              padding-left: 16px;
              font-family: "NanumSquareOTFLight";
            }
          }
        }
      }

      div.loginBtnBox{
        //border-bottom: 1px solid #d4d4d4;
        display: flex;
        flex-direction: row;
        button {
          width: 352px;
          margin: 20px 10px 16px 10px;
          background-color: #48A0B0;
        }
      }

      div.loginSaveBtn{
        display: flex;
        align-items: center;
        input{
          margin-right: 8px;
        }
        label{
          font-size: 14px;
          display: flex;
        }
      }

      div.loginPwdBtnBox{
        width: 100%;
        margin-top: 16px;
        font-size: 14px;
        button{
          width: calc(50% - 4px);
          font-size: 13px;
          &:first-child {
            margin-right: 8px;
          }
        }
      }
    }
  }

  div.interX {
    position: absolute;
    bottom: 16px;
    color: $dark;
  }
}


@media (max-width: 1064px) {
  div.loginContainer {
    div.loginBox {
      flex-direction: column;
      padding: 0 20px;
      background-color: transparent;
      box-shadow: none;
      div.left {
        width: 100%;
        border-bottom-left-radius: 0;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        div.loginBg{
          position: relative;
          display: none;

        }
        div.logoImg{
          position: relative;
          top: 0;
          height: 30px;
          img{
          }
          h3{

          }
        }
      }

      div.right {
        width: 100%;
        background-color: white;
        box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        div.top {

          span.title {
          }

          div.inputLoginBox {

            div.content {
              &:last-child{
              }

              label {
              }

              input {
              }
            }
          }
        }

        div.loginBtnBox{
          button {
          }
        }

        div.loginSaveBtn{
          input{
          }
          label{
          }
        }

        div.loginPwdBtnBox{
          button{
            &:first-child {
            }
          }
        }
      }
    }

    div.interX {
    }
  }
}


@media (max-width: 1064px) {
  div.loginContainer {
    div.loginBox {

      div.left {
        div.loginBg{
        }
        div.logoImg{
          img{
          }
          h3{
          }
        }
      }

      div.right {

        div.top {

          span.title {
          }

          div.inputLoginBox {

            div.content {
              &:last-child{
              }

              label {
              }

              input {
              }
            }
          }
        }

        div.loginBtnBox{
          button {
          }
        }

        div.loginSaveBtn{
          input{
          }
          label{
          }
        }

        div.loginPwdBtnBox{
          button{
            &:first-child {
            }
          }
        }
      }
    }

    div.interX {
    }
  }
}

