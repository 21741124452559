div.techPostWrap {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;

  span.arrowIcon {
    display: block;
    width: 100%;
    position: relative;
    top: 50px;
    left: 60px;
    cursor: pointer;

    &:hover {
      color: #fa9d72;
    }
  }

  div.techPostContainer {
    width: 100%;
    height: 100%;
    position: relative;
    //overflow-y: auto;
    //overflow-x: hidden;
    padding: 90px 0 20px 0;


    div.bg {
      width: 100%;
      //overflow: hidden;
      background-color: #f6f6f6;
      padding: 50px 0;
    }

    div.questionCon{
      margin-bottom: 50px;
    }

    div.questionCon, div.answerCon{
      width: 100%;
      //padding top 따로 걸기ㄴ
      padding: 0 140px;

      h3 {
        width: 100%;
        font-size: 20px;
      }

      div.subCon {
        width: 100%;
        padding: 10px 0;
        position: relative;
        opacity: 0.7;

        span {
          margin-right: 40px;

          &:after {
            content: '|';
            position: relative;
            right: -20px;
            opacity: 0.3;
          }

          &:first-child {
            font-weight: bold;
          }
        }
      }

      div.txt, div.answer {
        width: 100%;
        padding: 20px 0;
        line-height: 22px;
      }

      div.txt{
        width: 70%;
        border-top: 1px solid #c4c4c4;
        border-bottom: 1px solid #c4c4c4;
      }
    }

    div.formWrap {
      width: 70%;
      margin: 0 auto;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      div.formBox{
        width: 100%;
        margin-top: 30px;
        .ck.ck-content {
          width: 100%;
          height: 200px;
        }
      }

      button.submit-button {
        all: unset;
        width: 100px;
        height: 40px;
        background-color: #ff8000;
        color: #fff;
        font-size: 18px;
        font-weight: bold;
        border-radius: 5px;
        margin-top: 20px;
        vertical-align: middle;
        text-align: center;
      }
    }
  }
}

