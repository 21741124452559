@import "../color.scss";

div.signupContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: $background;

  div.loginBox {
    max-width: 656px;
    width: 100%;
    height: 440px;
    background-color: $white;
    font-size: 30px;
    border-radius: 8px;
    display: flex;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2);

    div.left {
      width: 256px;
      height: 100%;
      background-color: $dark;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      div.loginBg{
        width: 100%;
        height: 100%;
        position: absolute;
        //background: url("../../images/loginBg.svg") center/cover no-repeat;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }
      div.logoImg{
        position: absolute;
        top: 84px;
        img{
          width: 100%;
          height: 100%;
        }
      }
    }

    div.right {
      width: 400px;
      height: 100%;
      padding: 24px;
      box-sizing: border-box;

      div.top {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: start;
        color: $dark;
        font-weight: 400;

        span.title {
          font-size: 32px;
        }

        div.inputLoginBox {
          width: 100%;
          margin-top: 32px;
          display: flex;
          flex-direction: column;

          div.content {
            width: 100%;
            display: flex;
            flex-direction: column;
            margin-bottom: 24px;
            &:last-child{
              margin-bottom: 20px;
            }

            label {
              margin-bottom: 8px;
              padding-left: 8px;
              font-size: 14px;
            }

            input {
              height: 40px;
              border: 1px solid #d4d4d4;
              border-radius: 8px;
              padding-left: 16px;
            }
          }
        }
      }

      div.loginBtnBox{
        //border-bottom: 1px solid #d4d4d4;
        display: flex;
        flex-direction: row;
        button {
          width: 352px;
          margin: 20px 10px 16px 10px;
        }
      }

      div.loginSaveBtn{
        display: flex;
        align-items: center;
        input{
          margin-right: 8px;
        }
        label{
          font-size: 14px;
          display: flex;
        }
      }

      div.loginPwdBtnBox{
        width: 100%;
        margin-top: 16px;
        font-size: 14px;
        button{
          width: calc(50% - 4px);
          font-size: 13px;
          &:first-child {
            margin-right: 8px;
          }
        }
      }
    }
  }

  div.interX {
    position: absolute;
    bottom: 16px;
    color: $dark;
  }
}
.caption{
  font-size: 12px;
}
.pwShowCheckBox{
  border: 1px solid red;
  font-size: 12px;
  input.pwShowCheck{
    font-size: 12px;
  }
}
