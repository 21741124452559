#root {
  width: 100%;
  height: 100%;
  //min-width: 1024px;
  min-height: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  //background: linear-gradient(-45deg, #B5CFE3, #067697, #4AA1B1, #B5CEE2);
}

body {
  color: #292929;
  position: fixed;
  background: linear-gradient(-45deg, #B5CFE3, #067697, #4AA1B1, #B5CEE2);
  font-family: sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 100%;
  min-width: 100%;
  overflow-x: hidden;
  padding: 50px;
  box-sizing: border-box;
  background-size: 400% 400%;
  animation: gradient 4s ease infinite;

  -ms-overflow-style: none;

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
}
::-webkit-scrollbar {
  display: none;
}


a {
  text-decoration: none;
  color: #292929;
}


@media (max-width: 916px) {
  #root {
    width: 100%;
    height: 100%;
    min-height: 800px;
    display: flex;
    box-sizing: border-box;
  }
  body {
    padding: 10px;
  }
}
