* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ul, li {
  list-style: none;
}

div.slideContainer {
  display: flex;
  width: 100%;
  max-width: 1678px;
  justify-content: center;
  input{
    width: 100px;
    height: 100px;
    background-color: #0CBEF4;
  }

  ul.slideList {
    white-space: nowrap;
    font-size: 0;
    overflow: hidden;
  }

  li.slideItem {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 100%;
    transition: all 1s;
  }

  li.slideItem > a {
    display: block;
    width: auto;
    position: relative;
  }

  li.slideItem > a img {
    max-width: 100%;
    width: 1678px;
    height: 100%;
    min-height: 300px;
    object-fit: cover;
  }

  li.slideItem > a label {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 20px;
    border-radius: 50%;
    cursor: pointer;
  }
}

@media (max-width: 916px) {
  div.slideContainer {
    input{
    }

    ul.slideList {
      background-color: #eaeaea;
    }

    li.slideItem {
    }

    li.slideItem > a {
    }

    li.slideItem > a img {
    }

    li.slideItem > a label {
    }
  }
}

//.slideContainer .slideList .slideItem > a label.prev {left:20px;background:#333 url('../src/icons/previous.png') center center / 50% no-repeat;}
//.slideContainer .slideList .slideItem > a label.next {right:20px;background:#333 url('../src/icons/next.png') center center / 50% no-repeat;}


[name="slide"] {
  display: none;
}

#slide01:checked ~ .slideList .slideItem {
  transform: translateX(0);
  animation: slide01 10s infinite;
}

#slide02:checked ~ .slideList .slideItem {
  transform: translateX(-100%);
  animation: slide02 10s infinite;
}

#slide03:checked ~ .slideList .slideItem {
  transform: translateX(-200%);
  animation: slide03 10s infinite;
}

#slide04:checked ~ .slideList .slideItem {
  transform: translateX(-300%);
  animation: slide04 10s infinite;
}

#slide05:checked ~ .slideList .slideItem {
  transform: translateX(-400%);
  animation: slide05 10s infinite;
}


@keyframes slide01 {
  0% {
    left: 0%;
  }
  33% {
    left: 0%;
  }
  34% {
    left: -100%
  }
  66% {
    left: -100%;
  }
  67% {
    left: -200%;
  }
  99% {
    left: -200%;
  }
  100% {
    left: 0%;
  }
}

@keyframes slide02 {
  0% {
    left: 0%;
  }
  33% {
    left: 0%;
  }
  34% {
    left: -100%;
  }
  66% {
    left: -100%;
  }
  67% {
    left: 100%;
  }
  99% {
    left: 100%;
  }
  100% {
    left: 0%;
  }
}

@keyframes slide03 {
  0% {
    left: 0%;
  }
  33% {
    left: 0%;
  }
  34% {
    left: 200%;
  }
  66% {
    left: 200%;
  }
  67% {
    left: 100%;
  }
  99% {
    left: 100%;
  }
  100% {
    left: 0%;
  }
}

@keyframes slide04 {
  0% {
    left: 0%;
  }
  33% {
    left: 0%;
  }
  34% {
    left: 200%;
  }
  66% {
    left: 200%;
  }
  67% {
    left: 400%;
  }
  99% {
    left: 400%;
  }
  100% {
    left: 0%;
  }
}

@keyframes slide05 {
  0% {
    left: 0%;
  }
  33% {
    left: 0%;
  }
  34% {
    left: 500%;
  }
  66% {
    left: 500%;
  }
  67% {
    left: 400%;
  }
  99% {
    left: 400%;
  }
  100% {
    left: 0%;
  }
}
