div.lectureListWrap {
  width: 100%;
  //height: 100%;
  max-width: 1980px;
  //max-height: 1080px;
  //min-height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  //justify-items: center;
  //overflow: hidden;
  overflow-x: hidden;
  //box-shadow: 0 20px 20px -4px rgba(0, 0, 0, 0.2);
  position: relative;

  div.titleBox {
    width: 100%;

    h2 {
      width: 100%;
      margin: 50px 0 0 80px;
    }
  }

  div.lectureListContainer {
    width: 100%;
    //height: 100%;
    //min-height: 100%;
    position: relative;
    //overflow-y: auto;
    //overflow-x: hidden;
    //background-color: #00EE8F;

    div.categoryCon {
      width: 100%;
      padding: 30px 80px 10px 80px;
      display: flex;
      justify-content: space-between;

      ul {
        display: flex;
        align-items: center;

        li {
          margin-right: 20px;
          cursor: pointer;
          transition: 0.15s;

          &:hover {
            color: #fa9d72;
            font-weight: bold;
          }
        }
      }

      button {
        width: 100px;
        height: 40px;
        border: 1px solid #c4c4c4;
        transition: 0.2s;

        &:hover {
          background-color: #c4c4c4;
          color: #fff;
          font-weight: bold;
        }
      }
    }

    div.lectureListBox {
      width: 100%;
      padding: 10px 80px 30px 80px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      row-gap: 30px;
      column-gap: 30px;

      div.lectureList {
        min-width: 280px;
        height: 380px;
        border: 1px solid #c4c4c4;
        border-radius: 10px;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);

        div.lectureListImg {
          height: 50%;

          img {
            width: 100%;
            height: 100%;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
          }
        }

        div.lectureListTxt {
          width: 100%;
          height: 50%;
          word-break: break-all;
          padding: 15px;

          div.txtBox {
            width: 100%;
            height: 85%;
            border-bottom: 1px solid #c4c4c4;
            font-size: 15px;

            div.desc {
              margin: 10px 0 12px 0;
              font-size: 14px;
              line-height: 22px;
            }

            div.add {
              display: flex;
              justify-content: space-between;
              opacity: 0.6;
              font-size: 14px;
            }
          }

          div.writer {
            margin-top: 10px;
            font-weight: bold;
            display: flex;

            p {
              font-weight: normal;
              margin-right: 5px;
            }
          }
        }
      }
    }

    div.goSuvey {
      width: 140px;
      margin: 0 auto;
      margin-bottom: 30px;
      display: flex;
      height: 50px;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      color: white;
      border: none;
      background-color: #48A0B0;
      font-size: 15px;
      font-weight: bold;
      padding: 10px;
      border-radius: 15px;
      box-shadow: 0 10px 10px -4px rgb(0 0 0 / 20%);
    }
  }
}


@media (max-width: 1740px) {
  div.lectureListWrap {
    div.titleBox {
      h2 {
      }
    }

    div.lectureListContainer {
      div.categoryCon {
        ul {
          li {
            &:hover {
            }
          }
        }

        button {
          &:hover {
          }
        }
      }

      div.lectureListBox {
        //grid-template-columns: repeat(3, 1fr);
        //background-color: #1E2941;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;

        div.lectureList {
          width: 280px;

          div.lectureListImg {
            img {
            }
          }

          div.lectureListTxt {
            div.txtBox {
              div.desc {
              }

              div.add {
              }
            }

            div.writer {
              p {
              }
            }
          }
        }
      }
    }
  }
}


@media (max-width: 916px) {
  div.lectureListWrap {
    //max-height: 100%;

    div.titleBox {
      //background-color: #00EE8F;
      h2 {
        margin-left: 30px;
        margin-top: 30px;
      }
    }

    div.lectureListContainer {
      div.categoryCon {
        ul {
          li {
            &:hover {
            }
          }
        }

        button {
          &:hover {
          }
        }
      }

      div.lectureListBox {
        grid-template-columns: repeat(1, 1fr);

        div.lectureList {
          div.lectureListImg {
            img {
            }
          }

          div.lectureListTxt {
            div.txtBox {
              div.desc {
              }

              div.add {
              }
            }

            div.writer {
              p {
              }
            }
          }
        }
      }

      div.goSuvey {
        margin-bottom: 30px;
      }
    }
  }
}
