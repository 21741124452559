div.layoutContainer{
  width: 100%;
  height: 100%;
  border-radius:  20px;
  max-width: 1980px;
  //max-height: 1080px;
  background-color: white;
  display: flex;
  //overflow: hidden;
  box-shadow: 0 20px 20px -4px rgba(0, 0, 0, 0.2);
  position: relative;

  div.layoutRightContainer{
    width: calc(100% - 300px);
    max-width: 1980px;
    //height: 100%;
    //max-height: 1080px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    overflow-y: auto;
  }
}

@media (max-width: 916px) {
  div.layoutContainer{
    //overflow: auto;
    //flex-direction: column;
    max-height: 100%;
    min-height: 100%;
    //display: block;
    position: fixed;
    //justify-content: flex-start;
    //padding-top: 300px;
    display: flex;
    flex-direction: column;
    //overflow: visible;
    //overflow: auto;
    //background-color: #00EE8F;



    div.layoutRightContainer{
      width: 100%;
      //height: auto;
      //height: 100%;
      //min-height: 100%;
      //max-height: 100%;
      border-top-right-radius: 0px;
      //overflow-y: visible;
      border-bottom-right-radius: 20px;
      border-bottom-left-radius: 20px;
    }
  }
}
