div.postFormContainer {
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 99;
  div.postFormBox {
    width: 100%;
    padding: 10px 0 30px 0;
    background-color: #F8F9FA;

    div.answer{
      border-bottom: 1px solid #C6C7C8;
    }
  }

  div.formWrap{
    width: 900px;
    height: 600px;
    border-radius: 20px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    div.closeBtn{
      width: 100%;
      display: flex;
      justify-content: end;
      position: absolute;
      top: 30px;
      right: 30px;
      font-size: 24px;
      font-weight: bold;
      color: #A6A6A6;
      cursor: pointer;
    }

    div.formBox{
      max-width: 800px;
      width: 100%;
      height: 450px;
      div.formInput{
        width: 100%;
        margin-top: 20px;
        border: 1px solid #c4c4c4;
        border-bottom: none;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        box-sizing: border-box;
        display: flex;
        padding-right: 10px;
        select{
          padding: 0 20px;
          font-size: 15px;
          border: none;
          background: none;
          border-left: 1px solid #c4c4c4;
        }
        input.title-input{
          all: unset;
          width: 100%;
          text-align: left;
          font-size: 15px;
          padding: 8px 15px;

        }
      }
      .ck.ck-content{
        height: 360px;
      }
    }
  }

  button.submit-button {
    all: unset;
    width: 100px;
    height: 40px;
    background-color: #ff8000;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    border-radius: 5px;
    margin-top: 20px;
    vertical-align: middle;
  }
}