div.settingPageWrap {
  width: 100%;
  height: 100%;
  max-width: 1980px;
  max-height: 1080px;
  background-color: white;
  overflow: hidden;
  box-shadow: 0 20px 20px -4px rgba(0, 0, 0, 0.2);
  position: relative;
  padding-top: 30px;

  div.titleBox{
    width: 100%;
    h2{
      width: 100%;
      margin: 50px 0 0 80px;
    }

    p{
      margin: 10px 0 0 80px;
    }
  }

  div.settingPageContainer{
    width: 100%;
    height: 100%;
    padding: 30px 80px 10px 80px;
    position: relative;

    div.searchBox{
      width: 100%;
      height: 40px;
      display: flex;
      justify-content: end;
      div.inputCon{
        all: unset;
        width: 30%;
        height: 100%;
        border-radius: 5px;
        border: 1px solid #c4c4c4;
        position: relative;
        input{
          all: unset;
          width: 100%;
          height: 100%;
          padding-left: 50px;
        }
        span{
          position: absolute;
          top: 10px;
          left: 15px;
          color: #7c7c7c;
        }
      }
      button{
        width: 100px;
        height: 100%;
        margin-left: 15px;
        color: #fff;
        background-color: #ff8800;
        border-radius: 5px;
        transition: 0.2s;
        opacity: 0.8;
        &:hover{
          opacity: 1
        }
      }
    }
    div.contentBox{
      width: 100%;
      margin-top: 35px;
      padding: 35px 0;
      border-top: 2px solid #bbbbbb;
      border-bottom: 1px solid #bbbbbb;
      h3{
        padding: 15px 0;
      }
      div.content{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        div.contentTxt{
          display: flex;
          align-items: center;
          margin-bottom: 5px;
          div.postDate{
            margin-right: 15px;
          }
          span{
            opacity: 0.5;
            cursor: pointer;
          }
        }
      }
    }
  }
}