div.surveyPageContainer{
  //overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  div.surveyBox{
    width: 100%;
    height: 100%;
    //overflow-y: auto;
  }
}
