div.HomeContainer{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  div.homeContentBox{
    display: flex;
    flex-direction: column;
    justify-items: center;
    text-align: center;
    height: 100%;
    width: 100%;
    h1{
      color: #292929;
      margin-top: 50px;
      span.pointFont{
        color: #48A0B0;
        font-weight: 800;
        margin-right: 7px;
      }
    }
    h1:after {
      content: "";
      display: block;
      width: 170px;
      border-bottom: 1px solid #bcbcbc;
      margin: 20px auto;
    }
    div.homeTextContent{
      margin-top: 30px;
      font-size: 18px;
      span.pointFont{
        color: #48A0B0;
        font-weight: 800;
      }
    }
    div.contentBtnBox{
      display: flex;
      justify-content: center;
      button {
        margin: 30px 10px 0 10px;
        color: white;
        border: none;
        background-color: #48A0B0;
        font-size: 15px;
        font-weight: bold;
        padding: 10px;
        border-radius: 15px;
        box-shadow: 0 10px 10px -4px rgba(0, 0, 0, 0.2);
      }
    }
    //border-bottom: 1px solid #bec9e3;
  }
}

@media (max-width: 916px) {
  div.HomeContainer{
    div.homeContentBox{
      h1{
        span.pointFont{
        }
      }
      h1:after {
      }
      div.homeTextContent{
        margin: 50px 0;
        span{
          line-height: 40px;
        }
      }
      div.contentBtnBox{
        margin-bottom: 20px;
        button {
        }
      }
    }
  }
}

span.ti-cursor{
  display: none;
}
