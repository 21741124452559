div.postDetailContainer{
  width: 100%;
  //height: 100%;
  padding: 0 60px;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  //background-color: #00EE8F;

  span.arrowIcon{
    display: block;
    width: 100%;
    position: relative;
    top: 50px;
    cursor: pointer;
    &:hover{
      color: #fa9d72;
    }
  }

  div.titleCon{
    width: 90%;
    padding: 80px 60px 20px 60px;
    border-bottom: 1px solid #c4c4c4;
    display: flex;
    justify-content: space-between;

    div.titleBox{
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      div.avatar{
        width: 70px;
        height: 70px;
        background-color: #eaeaea;
        border-radius: 50%;
        position: relative;
        img{
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }

      div.txtBox{
        flex: 1;
        margin-left: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        div.topTxt{
          width: 100%;
          display: flex;
          justify-content: space-between;
          font-size: 18px;
          font-weight: bold;
          margin-top: 5px;
          margin-right: 5px;
          div.date {
            color: #999999;
            font-weight: lighter;

          }
        }

        div.bottomTxt{
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 10px;

          span.downloadIcon{
            display: flex;
            position: relative;
            cursor: pointer;
            align-items: center;
          }
        }
      }
    }
  }

  div.videoCon{
    height: 450px;
    //width: 100%;
    //background-color: lightblue;
    margin: 60px 0;
    display: flex;

    #comwelVideo{
      width: 100%;
      height: 100%;
      //background: black;
      background-color: #000000;
      position: relative;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      object-fit:  contain;
      border:none;
    }
    div.videosBox{
      //background-color: #1E2941;
      width: 100%;
      height: 100%;
      min-width: 300px;
      margin-left: 30px;
      //overflow-y: auto;
      display: flex;
      flex-direction: column;
      position: relative;
      div.videoImageBox{
        //background-color: #609EAE;
        margin-bottom: 10px;
        box-shadow: 1px 1px 3px rgba(0,0,0,0.2);
        border-radius: 2px;
        cursor: pointer;
        position: relative;
        display: flex;
        flex-direction: row;
        img{
          width: 168px;
          min-width: 168px;
          height: 94px;
          object-fit: fill;
          object-position: center;
          margin-right: 3px;
        }
        div.videoInfoBox{
          display: flex;
          flex-direction: column;
          width: 120px;
          justify-content: space-between;
          span.videoTitle{
            //position: absolute;
            width: 100px;
          }
          div.videoInfo{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 5px 0;
            box-sizing: content-box;
            span{
              font-size: 12px;
            }
            span.videoCreatedAt{

            }
            span.videoViewCnt{

            }
          }
        }
        &:hover{
          scale: 1.1;
        }
      }
    }
  }

  div.txtCon{
    width: 90%;
    position: relative;
    //overflow: hidden;
    padding: 30px 20px;
    border-top: 1px solid #c4c4c4;
    border-bottom: 1px solid #c4c4c4;
    line-height: 24px;
    display: flex;
    flex-direction: row;
    div.goSuvey{
      width: 140px;
      margin-right: 30px;
      display: flex;
      height: 50px;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      color: white;
      border: none;
      background-color: #48A0B0;
      font-size: 15px;
      font-weight: bold;
      padding: 10px;
      border-radius: 15px;
      box-shadow: 0 10px 10px -4px rgb(0 0 0 / 20%);
    }
  }

  div.buttons{
    width: 90%;
    display: flex;
    justify-content: space-between;
    padding-top: 30px;


    div.box{
      display: flex;
      justify-content: center;
      align-items: center;
    }

    div.leftBox{
      button{
        background-color: #c4c4c4;
        margin-right: 10px;
        color: #fff;
      }
    }

    div.rightBox{
      button{
        margin-bottom: 30px;
        border: 1px solid #c4c4c4;
        color: #999999;
        a{
          display: block;
        }
      }
    }
  }
}

button{
  all: unset;
  width: 100px;
  height: 35px;
  text-align: center;
  border-radius: 10px;
  font-weight: bold;
  cursor: pointer;
}

@media (max-width: 1784px){
  div.postDetailContainer{
    span.arrowIcon{
      &:hover{
      }
    }

    div.titleCon{
      div.titleBox{
        div.avatar{
          img{
          }
        }

        div.txtBox{

          div.topTxt{
            div.date {
            }
          }

          div.bottomTxt{
            span.downloadIcon{
            }
          }
        }
      }
    }

    div.videoCon{
      //height: 100%;
      //max-height: 450px;
      height: 380px;
      video{
      }
      #comwelVideo{
      }
      div.videosBox{

        div.videoImageBox{
          span.videoTitle{
          }
          img{
          }
          &:hover{
          }
        }
      }
    }

    div.txtCon{
      div.goSuvey{
      }
    }

    div.buttons{
      div.box{
      }
      div.leftBox{
        button{
        }
      }

      div.rightBox{
        button{
          a{
          }
        }
      }
    }
  }
}


@media (max-width: 1641px){
  div.postDetailContainer{
    span.arrowIcon{
      &:hover{
      }
    }

    div.titleCon{
      div.titleBox{
        div.avatar{
          img{
          }
        }

        div.txtBox{

          div.topTxt{
            div.date {
            }
          }

          div.bottomTxt{
            span.downloadIcon{
            }
          }
        }
      }
    }

    div.videoCon{
      height: 360px;
      video{
      }
      #comwelVideo{
      }
      div.videosBox{

        div.videoImageBox{
          span.videoTitle{
          }
          img{
          }
          &:hover{
          }
        }
      }
    }

    div.txtCon{
      div.goSuvey{
      }
    }

    div.buttons{
      div.box{
      }
      div.leftBox{
        button{
        }
      }

      div.rightBox{
        button{
          a{
          }
        }
      }
    }
  }
}

@media (max-width: 1550px){
  div.postDetailContainer{
    span.arrowIcon{
      &:hover{
      }
    }

    div.titleCon{
      div.titleBox{
        div.avatar{
          img{
          }
        }

        div.txtBox{

          div.topTxt{
            div.date {
            }
          }

          div.bottomTxt{
            span.downloadIcon{
            }
          }
        }
      }
    }

    div.videoCon{
      height: 350px;
      //max-height: 350px;
      video{
        background: transparent;
        background-size: cover;
        object-fit: cover;
      }
      #comwelVideo{
        max-width: 500px;

      }
      div.videosBox{

        div.videoImageBox{
          span.videoTitle{
          }
          img{
          }
          &:hover{
          }
        }
      }
    }

    div.txtCon{
      div.goSuvey{
      }
    }

    div.buttons{
      div.box{
      }
      div.leftBox{
        button{
        }
      }

      div.rightBox{
        button{
          a{
          }
        }
      }
    }
  }
}

@media (max-width: 1300px){
  div.postDetailContainer{
    //overflow-y: auto;
    span.arrowIcon{
      &:hover{
      }
    }

    div.titleCon{
      padding-left: 0;
      padding-right: 0;
      width: 100%;
      div.titleBox{
        div.avatar{
          img{
          }
        }

        div.txtBox{

          div.topTxt{
            div.date {
            }
          }

          div.bottomTxt{
            span.downloadIcon{
            }
          }
        }
      }
    }

    div.videoCon{
      flex-direction: column;
      height: 100%;
      //background-color: #0A1A7A;
      video{

      }
      #comwelVideo{
      }
      div.videosBox{
        //background-color: #00EE8F;
        min-width: 100%;
        margin: 0;
        margin-top: 30px;
        display: flex;
        //flex-direction: column;
        //justify-content: center;
        align-items: center;
        //overflow-y: visible;

        div.videoImageBox{
          width: 100%;
          height: 100%;
          //background-color: #0c4b76;
          max-width: 280px;
          span.videoTitle{
          }
          img{
            width: 100%;
            height: 180px;
            max-width: 280px;
          }
          &:hover{
          }
        }
      }
    }

    div.txtCon{
      width: 100%;
      height: 100px;
      min-height: 100px;
      align-items: center;
      //padding: 0;
      //padding: 100px;
      //background-color: #0CBEF4;

      div.goSuvey{
      }
    }

    div.buttons{
      margin-bottom: 30px;
      div.box{
      }

      div.leftBox{
        button{
        }
      }

      div.rightBox{
        button{
          a{
          }
        }
      }
    }
  }
}

@media (max-width: 1100px){
  div.postDetailContainer{
    span.arrowIcon{
      &:hover{
      }
    }

    div.titleCon{
      padding-left: 0;
      padding-right: 0;
      width: 100%;
      font-size: 14px;
      div.titleBox{
        div.avatar{
          width: 60px;
          height: 60px;
          img{
            width: 60px;
            height: 60px;
          }
        }

        div.txtBox{

          div.topTxt{
            div.title{
              font-size: 14px;
            }
            div.date {
              font-size: 14px;
              width: 80px;
              min-width: 80px;
            }
          }

          div.bottomTxt{
            span.downloadIcon{
              top:0;
            }
          }
        }
      }
    }

    div.videoCon{
      video{

      }
      #comwelVideo{
      }
      div.videosBox{

        div.videoImageBox{
          span.videoTitle{
          }
          img{
          }
          &:hover{
          }
        }
      }
    }

    div.txtCon{
      //width: 100%;
      padding: 20px 15px;
      //padding-top: 0;
      //padding-bottom: 0;
      height: auto;
      min-height: auto;
      //background-color: #00EE8F;
      //height: 100%;
      div.goSuvey{
        width: 130px;
        height: 45px;
        font-size: 12px;
      }
      span{
        font-size: 14px;
      }
    }

    div.buttons{
      div.box{
      }

      div.leftBox{
        button{
        }
      }

      div.rightBox{
        button{
          a{
          }
        }
      }
    }
  }
}

@media (max-width: 916px){
  div.postDetailContainer{
    span.arrowIcon{
      top: 0;
      margin: 20px 0;
      &:hover{
      }
    }

    div.titleCon{
      position: relative;
      width: 100%;
      padding: 0;
      //padding: 30px 60px 20px 60px;
      div.titleBox{
        padding: 10px 0;
        div.avatar{
          width: 50px;
          height: 50px;
          img{
          }
        }

        div.txtBox{

          div.topTxt{
            div.date {
            }
          }

          div.bottomTxt{
            span.downloadIcon{
              top: 0;
            }
          }
        }
      }
    }

    div.videoCon{
      margin-top: 20px;
      margin-bottom: 200px;
      video{

      }
      #comwelVideo{
      }
      div.videosBox{

        div.videoImageBox{
          span.videoTitle{
          }
          img{
          }
          &:hover{
            scale: 1;
          }
          &:active{
            box-shadow: 1px 1px 10px rgba(0,0,0,0.2);
          }
        }
      }
    }

    div.txtCon{
      flex-direction: column-reverse;
      //min-height: 200px;

      span{
        margin-top: 20px;
      }
      div.goSuvey{
        margin-top: 20px;
        //margin: 20px 0;
        margin-right: 0;
      }
    }

    div.buttons{
      div.box{
      }

      div.leftBox{
        button{
        }
      }

      div.rightBox{
        button{
          a{
          }
        }
      }
    }
  }
}

@media (max-width: 430px){
  div.postDetailContainer{
    padding: 0 10px;
    span.arrowIcon{
      &:hover{
      }
    }

    div.titleCon{
      div.titleBox{
        div.avatar{
          img{
          }
        }

        div.txtBox{

          div.topTxt{
            div.date {
            }
          }

          div.bottomTxt{
            span.downloadIcon{
            }
          }
        }
      }
    }

    div.videoCon{
      video{

      }
      #comwelVideo{
      }
      div.videosBox{

        div.videoImageBox{
          span.videoTitle{
          }
          img{
          }
          &:hover{
            scale: 1;
          }
        }
      }
    }

    div.txtCon{
      flex-direction: column-reverse;
      min-height: 200px;

      span{
        margin-top: 50px;
      }
      div.goSuvey{
        margin-top: 20px;
        //margin: 20px 0;
        margin-right: 0;
      }
    }

    div.buttons{
      div.box{
      }

      div.leftBox{
        button{
        }
      }

      div.rightBox{
        button{
          a{
          }
        }
      }
    }
  }
}
