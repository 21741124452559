//Primary Colors
$blue-100: #D6E4FF;
$blue-200: #ADC8FF;
$blue-300: #85A9FF;
$blue-400: #6690FF;
$blue-500: #3366FF;
$blue-600: #254EDA;
$blue-700: #1A39B6;
$blue-800: #102693;
$blue-900: #0A1A7A;
//Secondary Colors
$orange-100: #FFEFCC;
$orange-200: #FFDB99;
$orange-300: #FFC266;
$orange-400: #FFA93F;
$orange-500: #FF8000;
$orange-600: #DB6300;
$orange-700: #B74900;
$orange-800: #933400;
$orange-900: #7A2500;
//Base Colors
$black: #000000;
$dark: #1E2941;
$info: #3366FF;
$line: #E3E3E3;
$inactive: #E2E2E2;
$inactive-text: #979797;
$background: #F1F3F4;
$white: #FFFFFF;
//Quality Colors
$quality-best: #009DFF;
$quality-good: #71E887;
$quality-bad: #FF503D;
$quality-worst: #931326;
//Support Colors
$support-info: #0CBEF4;
$support-success: #5ED057;
$support-warning: #FCB72B;
$support-error: #FE0A02;
$info-100: #CCF7FF;
$info-200: #99EAFF;
$info-300: #66D6FF;
$info-400: #3FC0FF;
$info-500: #009DFF;
$info-600: #0079DB;
$info-700: #005AB7;
$info-800: #004093;
$info-900: #002D7A;
$success-100: #E8FDE3;
$success-200: #CDFCC8;
$success-300: #ACF8AB;
$success-400: #94F19C;
$success-500: #71E887;
$success-600: #52C773;
$success-700: #38A762;
$success-800: #248652;
$success-900: #156F48;
$warning-100: #FFF8D4;
$warning-200: #FFF0AA;
$warning-300: #FFE57F;
$warning-400: #FFDB60;
$warning-500: #FFCA2B;
$warning-600: #DBA71F;
$warning-700: #B78615;
$warning-800: #93670D;
$warning-900: #784408;
$error-100: #FFE8D8;
$error-200: #FFCBB1;
$error-300: #FFA88A;
$error-400: #FF876D;
$error-500: #FF503D;
$error-600: #DB2F2C;
$error-700: #B71E28;
$error-800: #931326;
$error-900: #7A0B24;
