  @import 'color.scss';
  //button
  button.btnXXS{
    border: none;
    height: 24px;
    font-weight: 400;
    border-radius: 8px;
    padding: 0 12px;
    box-sizing: border-box;
    font-size: 12px;
    text-align: center;
    cursor: pointer;
  }

  button.btnXS{
    border: none;
    height: 32px;
    font-weight: 400;
    border-radius: 8px;
    padding: 0 12px;
    box-sizing: border-box;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
  }

  button.btnS{
    border: none;
    height: 36px;
    font-weight: 400;
    border-radius: 8px;
    color: $white;
    padding: 0 16px;
    box-sizing: border-box;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
  }

  button.btnR{
    border: none;
    height: 40px;
    font-weight: 400;
    border-radius: 8px;
    color: $white;
    padding: 0 16px;
    box-sizing: border-box;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
  }

  button.btnL{
    border: none;
    height: 48px;
    font-weight: 400;
    border-radius: 8px;
    color: $white;
    padding: 0 16px;
    box-sizing: border-box;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
  }

  //icon button
  button.iconBtnS{
    border: none;
    height: 24px;
    background-color: $inactive;
    color: $inactive-text;
    font-size: 12px;
    border-radius: 8px;
    padding: 0 16px;
    cursor: pointer;
  }


  button.iconBtnR{
    border: none;
    height: 36px;
    border-radius: 8px;
    padding: 0 16px 0 8px;
    color: $white;
    background-color: $blue-500;
    display: flex;
    align-items: center;
    font-size: 14px;
    cursor: pointer;

    span.iconR{
      width: 16px;
      height: 16px;
      margin: 4px;
      display: flex;
      align-items: center;
    }
  }

  button.iconBtnL{
    border: none;
    height: 40px;
    border-radius: 8px;
    padding: 0 16px 0 8px;
    color: $white;
    background-color: $blue-500;
    display: flex;
    align-items: center;
    font-size: 14px;
    cursor: pointer;

    span.iconL{
      width: 24px;
      height: 24px;
      margin: 4px;
      display: flex;
      align-items: center;
    }
    span.txt{
      margin-left: 4px;
    }
  }

  //default
  button.defaultPrimary{
    background-color: $blue-500;
    color: $white;
    transition: 0.3s;
    &:hover{
      background-color: $blue-700;
      color: $white;
    }
  }

  button.defaultES{
    background-color: $blue-100;
    color: $blue-500;
    transition: 0.3s;
    &:hover{
      background-color: $blue-500;
      color: $white;
    }
  }

  button.defaultOutlined{
    background-color: $white;
    color: $blue-500;
    border: 1px solid $blue-500;
    transition: 0.3s;
    &:hover{
      background-color: $blue-100;
      color: $blue-500;
      border: 1px solid $blue-200;
    }div.btnBoxContent {
       margin-top: 4px;
       display: flex;
       flex-direction: column;

       span.btnBoxTxt {
         display: flex;
         flex-direction: column;
         align-items: center;
         font-size: 16px;

         p {
           display: block;
           width: 32px;
           height: 32px;
           margin-bottom: 8px;
         }
       }

       span.green {
         color: $success-500;
       }

       span.yellow {
         color: $warning-500;
       }

       span.red {
         color: $error-500;
       }

       div.btnBoxLine{
         width: 188px;
         height: 4px;
         background-color: pink;
         border-radius: 8px;
         &:first-child{
           margin-bottom: 20px;
         }
         &:last-child{
           margin-top: 20px;
         }
       }

       div.green {
         background-color: $success-500;
         box-shadow: 0 0 16px $success-500;
       }

       div.yellow {
         background-color: $warning-500;
         box-shadow: 0 0 16px $warning-500;
       }

       div.red {
         background-color: $error-500;
         box-shadow: 0 0 16px $error-500;
       }

     }
  }

  button.defaultGhost{
    background-color: transparent;
    color: $blue-500;
    transition: 0.3s;
    &:hover{
      background-color: $blue-100;
      color: $blue-500;
    }
  }

  //normal
  button.normalPrimary{
    background-color: $white;
    color: $black;
    border: 1px solid #d4d4d4;
    transition: 0.3s;
    &:hover{
      background-color: $blue-100;
      color: $blue-500;
    }
  }

  button.normalGhost{
    background-color: transparent;
    color: $black;
    transition: 0.3s;
    &:hover{
      background-color: $blue-100;
      color: $blue-500;
    }
  }

  //success
  button.successPrimary{
    background-color: $success-500;
    color: $success-900;
    transition: 0.3s;
    &:hover{
      background-color: $success-700;
      color: $black;
    }
  }

  button.successES{
    background-color: $success-100;
    color: $success-900;
    transition: 0.3s;
    &:hover{
      background-color: $success-500;
      color: $success-900;
    }
  }

  button.successOutlined{
    background-color: $white;
    color: $success-600;
    border: 1px solid $success-500;
    &:hover{
      background-color: $success-200;
      color: $success-900;
      border: 1px solid $success-400;
    }
  }

  button.successGhost{
    color: $success-600;
    transition: 0.3s;
    &:hover{
      background-color: $success-200;
      color: $success-900;
    }
  }

  //warning
  button.warningPrimary{
    background-color: $warning-500;
    color: $warning-900;
    transition: 0.3s;
    &:hover{
      background-color: $warning-700;
      color: $white;
    }
  }

  button.warningES{
    background-color: $warning-100;
    color: $warning-800;
    transition: 0.3s;
    &:hover{
      background-color: $warning-500;
      color: $warning-900;
    }
  }

  button.warningOutlined{
    background-color: $white;
    color: $warning-500;
    border: 1px solid $warning-500;
    transition: 0.3s;
    &:hover{
      background-color: $warning-500;
      color: $warning-900;
      border: 1px solid $warning-400;
    }
  }

  button.warningGhost{
    color: $warning-500;
    transition: 0.3s;
    &:hover {
      background-color: $warning-500;
      color: $warning-900;
    }
  }

  //danger
  button.dangerPrimary{
    background-color: $error-500;
    color: $white;
    transition: 0.3s;
    &:hover {
      background-color: $error-700;
      color: $white;
    }
  }

  button.dangerES{
    background-color: $error-200;
    color: $error-500;
    transition: 0.3s;
    &:hover {
      background-color: $error-500;
      color: $white;
    }
  }

  button.dangerOutlined{
    background-color: $white;
    color: $error-500;
    border: 1px solid $error-500;
    transition: 0.3s;
    &:hover {
      background-color: $error-200;
      color: $error-500;
      border: 1px solid $error-400;
    }
  }

  button.dangerGhost{
    background-color: transparent;
    color: $error-500;
    transition: 0.3s;
    &:hover {
      background-color: $error-200;
      color: $error-500;
    }
  }

  //special
  button.specialPrimary{
    background-color: $orange-500;
    color: $white;
    transition: 0.3s;
    &:hover {
      background-color: $orange-700;
      color: $white;
    }
  }

  button.specialES{
    background-color: $orange-200;
    color: $orange-500;
    transition: 0.3s;
    &:hover {
      background-color: $orange-500;
      color: $white;
    }
  }

  button.specialOutlined{
    background-color: $white;
    color: $orange-500;
    border: 1px solid $orange-500;
    transition: 0.3s;
    &:hover {
      background-color: $orange-200;
      color: $orange-500;
      border: 1px solid $orange-400;
    }
  }

  button.specialGhost{
    background-color: transparent;
    color: $orange-500;
    transition: 0.3s;
    &:hover {
      background-color: $orange-200;
      color: $orange-500;
    }
  }
